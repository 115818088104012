import fetch from "../auth/FetchInterceptor";

export const getCountries = async () => {
    return fetch({url: '/countries', method: 'get'})
}

export const createCompany = async (data) => {
    return fetch({url: '/companies', method: 'post', data})
}

export const editCompany = async ({id, data}) => {
    return fetch({url: `/companies/${id}`, method: 'put', data})
}

export const getAllCompanies = async () => {
    return fetch({url: '/companies', method: 'get'})
}

export const getCompanyById = async (id) => {
    return fetch({url: `/companies/${id}`, method: 'get'})
}
export const changeCurrentCompany = async (props) => {
    return fetch({url: `/user/current-company`, method: 'put', data: props})
}

export const getCompanyUsers = async (id) => {
    return fetch({url: `/company/users`, method: 'get'})
}