import fetch from "../auth/FetchInterceptor";

export const registerUser = async (data) => {
    return fetch({url: `/register`, method: 'post', data})
}

export const loginUser = async ({email, password}) => {
    return fetch({url: '/login', method: 'post', data: {email, password}})
}

export const logoutUser = async () => {
    return fetch({url: '/logout', method: 'post'})
}

export const getCurrentUser = async () => {
    return fetch({url: '/me', method: 'get'});
}

export const verifyToken = async (data) => {
    return fetch({url: '/user-invitation/verify-token', method: 'get', params:data});
}