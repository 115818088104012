import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getCurrentUser} from "../../api/auth";
import {getAllCompanies, getCompanyById} from "../../api/companies";

export const initialState = {
    userCompany: null,
    isUserCompanyLoading: false,
    userCompanyMessage: null,
    companies: null,
    areCompaniesLoading: false,
    companiesMessage: null,
}

export const getCompanies = createAsyncThunk('companies/get-all', async (data, {rejectWithValue}) => {
    try {
        const response = await getAllCompanies()
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const getCompany = createAsyncThunk('companies/get-by-id', async (data, {rejectWithValue}) => {
    try {
        const response = await getCompanyById(data)
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const companySlice = createSlice({
    name: "companies",
    initialState,
    reducers: {
        extraReducers: (builder) => {
            builder.addCase(getCompanies.pending, state => {
                state.areCompaniesLoading = true;
                state.companiesMessage = null;
            }).addCase(getCompanies.fulfilled, (state, action) => {
                state.areCompaniesLoading = false;
                state.companies = action.payload;
            }).addCase(getCompanies.rejected, (state, action) => {
                state.areCompaniesLoading = false;
                state.companiesMessage = action.payload;
            }).addCase(getCompany.pending, state => {
                state.isUserCompanyLoading = true;
                state.userCompanyMessage = null;
            }).addCase(getCompany.fulfilled, (state, action) => {
                state.isUserCompanyLoading = false;
                state.userCompany = action.payload;
            }).addCase(getCompany.rejected, (state, action) => {
                state.isUserCompanyLoading = false;
                state.userCompanyMessage = action.payload;
            })
        }
    }
})

export default companySlice.reducer